<template>
<v-dialog v-model="show" max-width="500px">
  <v-card>
    <v-card-title class="text-h5">
      {{ $t('confirmBox.title')}}
    </v-card-title>
    <v-card-text>
      {{ $t('confirmBox.errorMessage')}}
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="default" @click="this.cancel">{{ $t('confirmBox.cancel')}}</v-btn>
      <v-btn color="#424752" style="color: #fff;" 
        @click="this.sendWithoutFiles">{{ $t('confirmBox.sendWithoutFiles')}}</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
export default {
  props: ['visible'],
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    sendWithoutFiles() {
      this.$emit('send-without-files')
    }
  },
  computed: {
    show: {
      get () {
        return this.visible
      },
      set () {
        this.$emit('cancel')
      }
    }
  }
}
</script>